/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Button, Text, TitleMain, ContactForm, Title } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Rezervace"}>
        <SiteHeader />

        <Column className="--left pb--20 pt--50" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--20 pr--20 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--sbtn2 btn-box--left ff--3 fs--18 w--300 swpf--uppercase ls--04 lh--2" href={"/hr/"} content={"Back"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right fs--18 w--300 text-box--invert swpf--uppercase ls--04 lh--2 pb--08 pt--08" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary);\">Main course</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--10" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--20 pr--20 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <TitleMain className="title-box fs--154 w--300 ls--01 lh--12" style={{"maxWidth":""}} content={"Booking"}>
              </TitleMain>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"dbg7kfk0buc"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--1" anim={"2"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 ff--2 fs--22 w--300" style={{"maxWidth":1118}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"RESERVE","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}} border={null}>
          
          <ColumnWrap className="column__flex --right el--2 pb--30 pl--20 pr--20 pt--30 flex--top" style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--62 swpf--uppercase" content={"<span style=\"color: var(--color-dominant);\">Main course</span>"}>
              </Title>

              <Text className="text-box text-box--left ff--3 fs--16 w--300 text-box--invert ls--04 lh--2 mt--30" style={{"maxWidth":570}} content={"<span style=\"color: var(--color-dominant);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>.</span>"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--0 pl--0" innerClassName="pb--02 pl--0" content={"<span style=\"color: var(--color-dominant);\">Return to Homepage</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/hr/photogallery"} content={"<span style=\"color: var(--color-dominant);\">Photogallery</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/hr/menu"} content={"<span style=\"color: var(--color-dominant);\">Menu</span>"}>
              </Button>

              <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor2 btn-box--left ff--1 fs--22 w--300 btn-box--invert ls--04 mt--20 pl--0" innerClassName="pb--02 pl--0" href={"/hr/booking"} content={"<span style=\"color: var(--color-dominant);\">Booking"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}